/*常规验证码*/
.verify-code {
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}

.cerify-code-panel {
  height: 100%;
  overflow: hidden;
}

.verify-code-area {
  float: left;
}

.verify-input-area {
  float: left;
  width: 60%;
  padding-right: 10px;
}

.verify-change-area {
  line-height: 30px;
  float: left;
}

.varify-input-code {
  display: inline-block;
  width: 100%;
  height: 25px;
}

.verify-change-code {
  color: #337ab7;
  cursor: pointer;
}

.verify-btn {
  width: 200px;
  height: 30px;
  background-color: #337ab7;
  color: #ffffff;
  border: none;
  margin-top: 10px;
}

/*滑动验证码*/
.verify-bar-area {
  position: relative;
  background: rgba(195, 195, 195, 0.26);
  text-align: center;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 20px;
  -webkit-border-radius: 20px;
}

.verify-bar-area .verify-move-block {
  color: #fff;
  width: 42px;
  height: 42px;
  position: absolute;
  top: -2px;
  left: 0;
  background: #fff;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(0, 95, 67, 0.2);
  z-index: 10;
}

.verify-bar-area .verify-move-block:hover {
  background-color: #337ab7;
  color: #ffffff;
}
.verify-bar-area .verify-move-block .iconbofangfanhui {
  width: 42px;
  height: 42px;
  font-size: 20px !important;
  /* font-weight: bold; */
  /* color: #9fdcca !important; */
  background: url("../assets/new_ui/huadong.png") no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  line-height: 42px;
}
.verify-bar-area .verify-move-block .iconclose {
  width: 42px;
  height: 42px;
  font-size: 20px !important;
  /* font-weight: bold; */
  /* color: #9fdcca !important; */
  background: url("../assets/new_ui/close-icon.png") no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  vertical-align: middle;
  height: 42px;
  line-height: 42px;
}

.verify-bar-area .verify-left-bar {
  position: absolute;
  top: 0px;
  left: -1px;
  background: #63c450;
  cursor: pointer;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 20px 0 0 20px;
  overflow: visible !important;
  min-width: 19px !important;
}
.verify-bar-area .verify-left-bar:after {
  content: "";
  display: inline-block;
  width: 19px;
  height: 38px;
  border-radius: 0 12px 12px 0;
  position: absolute;
  right: -19px;
  top: 0;
  background: #63c450;
}

.verify-img-panel {
  margin: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.verify-img-panel .verify-refresh {
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: none !important;
}

.verify-img-panel .iconrefresh {
  font-size: 20px;
  color: #fff;
}

.verify-img-panel .verify-gap {
  background-color: #fff;
  position: relative;
  z-index: 2;
  border: 1px solid #fff;
}

.verify-bar-area .verify-move-block .verify-sub-block {
  position: absolute;
  text-align: center;
  z-index: 3;
  border: 1px solid #fff;
}

.verify-bar-area .verify-move-block .verify-icon {
  font-size: 18px;
}

.verify-bar-area .verify-msg {
  z-index: 3;
  color: #ababab;
}
