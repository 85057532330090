// 输入框
.el-input {
  width: 280px;
  font-family: DFPFangYuanW7;

  .el-input__inner {
    // height: 36px;
    // line-height: 36px;
    // height: 0.44rem;
    // line-height: 0.44rem;
    color: #448816;
    background: #fff;
    border-radius: 10px;
    border: 1.5px solid #579130 !important;
    padding-left: 20px;
  }

  .el-input__inner::placeholder {
    color: #9bd277;
  }

  &.is-disabled {
    .el-input__inner {
      color: #579130;
      background: #579130;

      &::placeholder {
        color: #579130;
      }
    }
  }
}

// 选择框
.el-select {
  .el-input {
    .el-input__suffix {
      height: 36px;
      line-height: 36px;
      height: 44px;
      line-height: 44px;
    }

    .el-input__icon {
      height: 44px;
      line-height: 44px;
      color: #46851b;
      font-weight: 1000;
      // background: url(../assets/new_ui/jt.png) no-repeat;
      // // background-size: 100% 100%;
    }
  }
}

// 选择下拉框
.el-select-dropdown {
  border: 0px solid transparent;

  .el-select-dropdown__list {
    .el-select-dropdown__item {
      font-family: DFPFangYuanW7;

      &.selected {
        // color: #8f4d00;
        color: #448816;
        font-weight: normal;
      }

      &.hover {
        // background: #ffecbe;
        color: #448816;

        background: #e3facd;
      }
    }
  }
}

// 按钮--primary、default
.el-button {
  // min-width: 6vw;
  // height: 4vh;
  height: 42px;
  line-height: 40px;
  font-family: DFPFangYuanW7;
  font-size: 16px;
  color: #fff;
  padding: 0;
  text-align: center;
  border: 0;
  // background-color: #e3facd !important;
  box-shadow: 0px 2px 4px 0px rgba(107, 152, 94, 0.4);
  border-radius: 10px;
  position: relative;
  z-index: 5;

  &.el-button--primary {
    background: #67b035;
    border-color: #67b035;

    &:not(.is-disabled):hover,
    &:not(.is-disabled):active,
    &:not(.is-disabled):focus {
      color: #fff;
      background: #85ca56;
      border-color: #fff;
    }

    &.is-disabled {
      color: #ffffff;
      background-color: #c2eda6;
      border-color: #c2eda6;

      &:hover {
        color: #ffffff;
        background-color: #c2eda6;
        border-color: #c2eda6;
      }
    }
  }

  &.el-button--default {
    color: #448816;
    // background: #e3facd;
    background-color: #e3facd;

    // border-color: #d8a121;
    &:not(.is-disabled):hover,
    &:not(.is-disabled):active,
    &:not(.is-disabled):focus {
      color: #448816;
      background: #ade787;
      // border-color: #d8a121;
    }

    &.is-disabled {
      color: #67b035;
      background-color: transparent;

      // border-color: #ececec;
      &:hover {
        color: #67b035;
        // background-color: #ade787;
        // border-color: #ececec;
      }
    }
  }
}

// loading
.el-loading-mask {
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  top: 120px;
  transition: opacity 0.1s;

  .el-loading-spinner {
    // margin-left: -50px;
    margin-left: -0.5rem;
    // margin-top: -160px;
    display: inline-block;
    width: 100px;
    height: 100px;
    background-image: url("../assets/loading.png");
    background-repeat: no-repeat;
    animation: spin 2s infinite linear;

    .circular {
      display: none;
    }
  }
}

// 抽屉
.el-popup-parent--hidden {
  overflow: hidden !important;
}

// 弹框
.self-el-dialog {
  border-radius: 20px;
  background: #f7ffef;
  min-height: 300px;
  margin-top: 2rem !important;

  // width: 6rem !important;
  // min-height: 4.2rem;
  .el-dialog__header {
    height: 38px;
    background-color: #ade787;
    background-image: url("../assets/dialog-header_bg.png");
    background-repeat: repeat-x;
    border-radius: 20px 20px 0 0;

    .el-dialog__headerbtn {
      display: inline-block;
      width: 30px;
      height: 30px;
      top: -30px;
      right: -30px;
      cursor: pointer;

      .el-dialog__close {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-image: url("../assets/new_ui/icon-close.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &:before {
          content: "";
        }

        &:hover {
          filter: saturate(125%) brightness(108%);
        }
      }
    }
  }

  .el-dialog__body {
    padding: 30px 40px;

    // padding: 30px 0.7rem;
    .dialog-title {
      width: 100%;
      text-align: center;
      height: 18px;
      line-height: 18px;
      font-size: 18px;
      font-family: DFPFangYuanW7;
      color: #448816;
      margin-bottom: 0.2rem;
    }

    .self-el-form {
      .el-form-item {
        .el-form-item__content {
          .el-input__inner {
            border: 0px solid transparent;
          }
        }
      }
    }
  }
}

// 表单
.self-el-form {
  font-family: DFPFangYuanW7;
  padding-bottom: 20px;

  .el-form-item {
    margin-bottom: 30px;

    .el-form-item__label {
      font-size: 14px;
      font-family: DFPFangYuanW7;
      color: #448816;
    }

    .el-form-item__content {
      line-height: 36px;

      .el-input__inner {
        border: 0.121rem solid #46851b;
      }
    }
  }
}

// 提示
.el-message {
  min-width: 220px;
  font-family: DFPFangYuanW7;
  font-size: 25px;
}

// 时间 日期选择器
.el-year-table td .cell:hover,
.el-year-table td.today .cell,
.el-year-table td.current:not(.disabled) .cell {
  color: #579130;
}

.el-month-table td.today .cell,
.el-month-table td .cell:hover,
.el-month-table td.current:not(.disabled) .cell {
  color: #579130;
}

.el-picker-panel__shortcut:hover,
.el-picker-panel__icon-btn:hover,
.el-date-table td.available:not(.current):not(.start-date):not(.end-date):hover,
.el-date-table td.today span,
.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
  color: #579130;

  div,
  span {
    color: #579130;
  }
}

.el-date-table td.end-date span,
.el-date-table td.start-date span,
.el-date-table td.current:not(.disabled) span {
  background-color: #579130;
}

.el-date-editor {

  .el-range__icon,
  .el-range-separator,
  .el-input__icon {
    line-height: 28px;
  }
}

.el-picker-panel {
  font-family: DFPFangYuanW7;
  border: 0px solid transparent;

  .el-picker-panel__sidebar {
    padding-top: 16px;

    .el-picker-panel__shortcut {
      line-height: 32px;
    }
  }

  .el-picker-panel__body-wrapper {
    .el-picker-panel__body {
      .el-date-picker__time-header {
        .el-input__inner {
          border-width: 1px;
          border-color: #f8c54f;
          line-height: 30px !important;
        }

        .el-time-panel {
          .el-time-panel__footer {
            .el-time-panel__btn.confirm {
              color: #8f4d00;
            }
          }
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding-right: 12px;

    .el-button {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
    }
  }
}

.el-textarea__inner {
  // height: 100%;
  min-height: 1vw;
  border: none;
  resize: none;
}

.el-textarea__inner::-webkit-scrollbar {
  display: block;
  width: 5px;
}

// 伪 ::scrollbar-thumb 元素设置

.el-textarea__inner::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #448816;
}

.el-message-box {
  width: 500px;
  height: 230px;
  border-radius: 20px;
  overflow: visible;
}

.el-message-box__header {
  height: 40px;
  background: url('../assets/dialog-header_bg.png') no-repeat;
  background-color: #ade787;
  border-radius: 20px 20px 0 0;

  .el-message-box__title {
    font-size: 0;
  }

  .el-message-box__headerbtn {
    background-color: #e3facd;
    top: -24px;
    right: -25px;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
}

.el-message-box__content {
  height: 100px;
  font-size: 20px;
  font-family: DFPFangYuanW7 !important;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .el-message-box__message {
    p {
      color: #448816;
    }
  }
}

.el-message-box__btns {
  .el-button {
    width: 6vw;
    max-width: 120px;
  }
}