@import './normalize.less';
@import './reset.less';
@import './base.less';
@import './utils.less';
@import './animation.less';
@import './element-ui-edit.less';
@import './verify.css';
@font-face {  
  font-family: 'DFPFangYuanW7';  //重命名字体名
  src: url('font-huakangyuanfang.ttf');  //引入字体
  font-weight: normal;  
  font-style: normal;  
}