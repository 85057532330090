/**
 * @Desc 工具class
 */
@color-main: #03b2b2;

div:not(#app)::-webkit-scrollbar,
ul::-webkit-scrollbar,
section::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  /* 滚动条整体样式 */
  margin-top: 3px;
  width: 6px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 6px;
}

div:not(#app)::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  // background-color: #42bbbb;
  // background-color: #46851b;
  background-color: #d8e9ca;
  border-radius: 2.5px;
}

div:not(#app)::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track,
section::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  /* border-radius: 10px; */
  background: #fff;
  display: none;
}
b,
strong {
  font-weight: bolder;
}

/* 可点击 */
.clickable,
.cursor,
.pointer {
  cursor: pointer !important;
}

/* 不可点击 */
.not-allowed {
  cursor: not-allowed !important;
}

.auto-cursor {
  cursor: auto;
}

.default-cursor {
  cursor: default !important;
}
.fill {
  width: 100%;
  height: 100%;
}

/* 水平垂直居中 */
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 左浮动 */
.float-left {
  float: left !important;
}

/* 右浮动 */
.float-right {
  float: right !important;
}

/* 清除浮动 */
.clear-float {
  zoom: 1;
  &:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}

/* 溢出省略 */
.no-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-wrap-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-wrap-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* 禁止文本选中 */
.not-select {
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

.visibility {
  visibility: visible !important;
}

.visibility-h {
  visibility: hidden !important;
}

.bgc-transparent {
  background-color: transparent !important;
}

.font-bold {
  font-weight: bold !important;
}
.color-red {
  color: #ee7a7a !important;
  // font-family: "华康方圆体W7(P)" !important;
}
.color-blur {
  // color: #48b1ff !important;
  color: #fcae2c !important;
}
.background-transparent {
  background: transparent !important;
}
.bg-white {
  background-color: #ffffff !important;
}

.display-none {
  display: none !important;
}
.display-block {
  display: block !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.overflow-h {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

/* 水平垂直居中 */
.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.vertical-bottom {
  vertical-align: bottom;
}
.vertical-middle {
  vertical-align: middle;
}

.width-auto {
  width: auto;
}

.height-auto {
  height: auto;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}
.font-weight-bold {
  font-weight: bold;
}

.content-width-1300 {
  width: 1300px !important;
  margin: 0 auto;
}
.font-family-normal {
  font-family: Avenir, DFPFangYuanW7, Helvetica, Arial, sans-serif !important;
}

// 播放器
#_preview {
  box-shadow: 0 0px 40px 0 rgba(0, 46, 54, 0.2);
  #center {
    #pic,
    #container,
    #otherType {
      background-color: #fff;
      background-image: none !important;
      // z-index: 10;
    }
  }
  .border {
    border-color: #2c98ff !important;
  }
}

.login-btn {
  width: 200px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #448816;
  background: #67b035;
  box-shadow: 0px 2px 2px 0px rgba(178, 178, 178, 0.5);
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: #ade787;
  }
}

// 滑动验证
.verify-card-popover {
  width: 280px;
  height: 220px;
  // width: 2.86rem;
  // height: 2.2rem;
  padding: 8px 16px !important;
  font-family: DFPFangYuanW7;
  border-radius: 10px;

  .top-box {
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    .card-title {
      color: #448816;
    }
    .change-it {
      color: #fcae2c;
      margin-right: 10px;
      cursor: pointer;
      .change-img {
        width: 12px;
        height: 12px;
        position: relative;
        top: 0.014rem;
      }
      .iconrefresh {
        font-size: 14px;
        padding-right: 6px;
      }
      &:hover {
        opacity: 0.8;
      }
    }

    .iconclose {
      color: #868686;
    }
  }
  .popper__arrow {
    left: 15px !important;
  }
  #mpanel4 {
    width: 246px;
    .verify-img-out {
      height: 110px;
      margin: 10px 0 13px 0;

      // width: 2.5rem;
      // height: 1.42rem !important;
    }
    // .verify-img-canvas {
    //   width: 100%;
    //   height: 100%;
    //   width: 2.5rem;
    //   height: 1.42rem !important;
    // }
    // .verify-sub-block {
    //   width: 0.483rem;
    //   height: 0.483rem;
    // }
  }
}
.verify-card-popover-hide {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
}

.error-page-content {
  width: 986px;
  height: 679px;

  width: 9.86rem;
  height: 6.79rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  text-align: center;

  .shade_box {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;
    border: 0.15rem solid #afe98a;
    border-radius: 1.2rem;
    // background-color: #ffe6b8;
    background: url("../assets/ui_ai-fe/个人中心背景图.jpg") no-repeat;
    background-size: 100% 102%;
    display: flex;
    justify-content: center;
    text-align: center;

    .text_box {
      // margin-top: 200px;
      margin-top: 2.5rem;
      margin-left: 0.5rem;
      padding-top: 0.2rem;
      font-size: 0.18rem;
      .reload {
        margin-top: 0.2rem;
        display: inline-block;
        width: 1.35rem;
        height: 0.36rem;
        line-height: 0.36rem;
        background: #67b035;
        border-radius: 0.1rem;
        color: #fff;
      }
    }
  }
  .error_shade {
    width: 11.74rem;
    height: 0.85rem;
    position: absolute;
    bottom: -0.4rem;
    left: -0.85rem;
  }

  .error-img {
    vertical-align: middle;
    // margin-top: 200px;
    margin-top: 2.5rem;

    width: 1.7rem;
    height: 1.56rem;
    // flex: 1;
  }

  .p2 {
    font-size: 0.16rem;
    color: #448816;
    // padding-top: 30px;
    line-height: 0.3rem;
  }

  .error-text {
    height: 3.5rem;
    display: inline-block;
    padding-left: 1rem;
    vertical-align: middle;

    .p1 {
      font-size: 0.7rem;
      padding-top: 0.9rem;
    }

    .p2 {
      font-size: 0.2rem;
    }
  }
}

.card-wrapper {
  // width: 702px;
  width: 100%;
  height: 100%;
  // height: 492px;
  // height: calc(100vh - 300px);
  // min-height: 500px;
  // max-height: 700px;
  // background: #f7ffef;
  border: 0.18rem solid #ade787;
  box-sizing: border-box;
  background: url("../assets/ui_ai-fe/个人中心背景图.jpg") no-repeat;
  background-size: 101% 101%;
  border-radius: 1.28rem;
  // margin-left: 106px;
  // margin-top: 60px;
  position: relative;
  // overflow: hidden;
  .img-bottom-bg {
    position: absolute;
    // bottom: -44px;
    // right: -108px;
    // width: 260px;

    bottom: -0.44rem;
    right: -1.08rem;
    width: 2.6rem;
  }
  // @media (max-height: 900px) {
  //   margin-top: 10px !important;
  // }
}

.no-data-box {
  text-align: center;
  .img-no-data {
    margin-top: 90px;
    margin-bottom: 12px;
  }
  .no-data-title {
    color: #8f4d00;
    color: #448816;
  }
  .login-btn-box {
    margin-top: 0.2rem;
    .login-btn {
      display: inline-block;
      background: #ffcd72;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.basic-info-wrapper {
  margin-top: 0 !important;
}
.no-data-text {
  font-size: 0.16rem;
}

.el-message .el-message--success {
  font-size: 20px;
}
